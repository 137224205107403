import { useEffect, useState } from "react";

const Counter = ({ title, length, className, background = `bg-dark` }) => {
    const [badgeBackground, setBadgeBackground] = useState(background);

    useEffect(() => {
        switch (true) {
            case (title.length > length - 40 && title.length < length - 10): {
                setBadgeBackground('bg-warning text-dark');
                break;
            }
            case (title.length >= length - 10): {
                setBadgeBackground('bg-danger');
                break;
            }
            default: {
                setBadgeBackground(background);
            }
        }
    }, [title])

    return (
        <span className={`badge rounded-pill ${badgeBackground} ${className}`}>
            {title.length} of {length}
            <span className="visually-hidden">characters</span>
            <i className="ms-1 bi bi-text-wrap"></i>
        </span>
    )
}

export default Counter;
