import { useContext, useEffect, useRef } from "react";
import PromptAnswer from "./prompt-answer";
import { useState } from "react";
import { Collapse } from "bootstrap";
import Counter from "./counter";
import { PromptContext } from "../store/prompt-store";

const PromptItem = ({ el }) => {
    const { maxLength } = useContext(PromptContext);
    const [collapse, setCollapse] = useState(false);
    const ref = useRef();

    useEffect(() => {
        const bsCollapse = new Collapse(ref.current, { toggle: false })
        collapse ? bsCollapse.show() : bsCollapse.hide();
    }, [collapse])

    return (
        <div className="accordion-item">
            {el.title && <h2 className="accordion-header">
                <button className={`accordion-button ${collapse ? `collapsed` : ``}`} type="button" onClick={() => setCollapse(!collapse)}>
                    <i className="bi bi-terminal"></i>
                    <span className="fw-medium mx-2 flex-grow-1">{el.title}</span>
                    <Counter className={`align-self-center justify-self-end mx-3 text-muted`} title={el.title} length={maxLength} />
                </button>
            </h2>}
            <div ref={ref} className="accordion-collapse collapse">
                <div className='accordion-body row g-3 row-cols-1 row-cols-md-3 bot-list'>
                    {el.answers.map((item, index) =>
                        <PromptAnswer item={item} key={index} />
                    )}
                </div>
            </div>
        </div>
    )
}

export default PromptItem;
