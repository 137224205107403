import { useContext } from "react";
import { PromptContext } from "../store/prompt-store";
import PromptItem from "./prompt-item";

const Prompts = () => {
    const { prompts } = useContext(PromptContext);

    return (
        prompts.length > 0 && <div className="mt-4 card">
            <div className="card-header fs-6 text-center">
                <i className="bi bi-clock-history me-2"></i>
                <span>Prompt History</span>
            </div>
            <div className="accordion accordion-flush" id='accordion'>
                {prompts.map((el, key) => el.title && <PromptItem el={el} key={key} />)}
            </div>
        </div>
    )
}

export default Prompts;
