export default function Faq({ img }) {

    return (
        <div className='row g-4'>
            <div className='col-lg-4 d-flex align-items-center align-items-lg-start justify-content-center'>
                <div className='sticky-top rounded rounded-3 overflow-hidden img-placeholder d-flex justify-content-start align-items-center'>
                    <img src={img} className='object-fit-cover' alt='Network' />
                </div>
            </div>
            <div className='col-lg-8'>
                <h3 className='h5 text-primary'>What is Rawbot?</h3>
                <p>&mdash; Rawbot is an intuitive platform designed to compare the performance of various artificial intelligence (AI) models, allowing users to choose the best model for their specific projects and applications.</p>
                <h3 className='h5 text-primary'>Who can benefit from using Rawbot?</h3>
                <p>&mdash; Researchers, AI engineers, developers, and businesses integrating AI solutions can benefit from Rawbot's AI model comparisons, making informed decisions about which AI models are best suited for their needs.</p>
                <h3 className='h5 text-primary'>Why should I choose Rawbot over other alternatives?</h3>
                <p>&mdash; You should choose Rawbot over other alternatives because it provides a unique combination of benefits, making it an ideal solution for AI model comparison:</p>
                <ol>
                    <li><strong>User-friendly Interface:</strong> Rawbot is designed with simplicity and ease of use in mind, allowing users of all levels of expertise to effectively navigate and utilize the platform.</li>
                    <li><strong>Comprehensive Comparisons:</strong> Rawbot facilitates side-by-side AI model evaluations, offering in-depth insights into performance, strengths, weaknesses, and overall suitability for your specific requirements.</li>
                    <li><strong>Time and Resource Savings:</strong> By streamlining the AI model selection process, Rawbot enables you to quickly identify the most suitable AI models, reducing trial-and-error and accelerating your projects.</li>
                    <li><strong>Wide Range of AI Models:</strong> Rawbot supports a diverse array of popular and emerging AI models, ensuring you have access to the latest AI technology landscape.</li>
                    <li><strong>Continuous Improvement:</strong> The platform is regularly updated and enhanced based on user feedback and market trends, ensuring you always have access to the best possible AI model comparison experience.</li>
                </ol>
                <p>In summary, Rawbot stands out from other platforms due to its focus on user-friendliness, insightful comparisons, efficiency, and commitment to keeping pace with the evolving AI landscape. It's a comprehensive and effective solution for selecting the best AI models tailored to your specific needs.</p>
                <h3 className='h5 text-primary'>How does Rawbot simplify the AI model selection process?</h3>
                <p>&mdash; Rawbot streamlines AI model comparison with a user-friendly interface that offers side-by-side evaluation, helping users quickly identify the strengths and weaknesses of each model based on their performance metrics.</p>
                <h3 className='h5 text-primary'>Is Rawbot actively updated and improved?</h3>
                <p>&mdash; Yes, Rawbot is committed to enhancing its platform by adding new features, increasing supported AI model coverage, and regularly improving the user experience based on user feedback and market trends.</p>
                <h3 className='h5 text-primary'>Are there any limitations in Rawbot?</h3>
                <p>&mdash; Rawbot currently imposes a <strong>limit of 140 characters for prompts and generates outputs up to 100 tokens</strong>. These restrictions are in place because the platform is free for all users, and we aim to keep it accessible to everyone. In the future, if we find ways to monetize or secure funding, the constraints may be lifted or increased to offer a more comprehensive experience.</p>
                <h3 className='h5 text-primary'>Are you planning to add new models to Rawbot?</h3>
                <p className='mb-0'>&mdash; Yes, in the very near future, we plan to expand our offerings by adding new AI models. For some models, we're currently on the waitlist, while others are not yet available to us. We're continuously working to incorporate more models as they become accessible, enhancing Rawbot's capabilities and user experience.</p>
            </div>
        </div>
    );
};
