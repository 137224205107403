import { Fragment } from "react";
import PromptTitle from "./prompt-title";
import { availableBots } from "../data";
import { Answer } from "../store/answer-store";
import Bot from "./bot";
import Prompts from "./prompts";

export default function Answers() {

    return (
        <Fragment>
            <PromptTitle index={null} />
            <div className='row g-3 row-cols-1 row-cols-md-3 bot-list'>
                {availableBots.map((item, key) =>
                    <Answer key={key}>
                        <Bot item={item} />
                    </Answer>
                )}
            </div>
            <Prompts />
        </Fragment>
    )
}
