const OPENAI_API_KEY = process.env.REACT_APP_OPENAI_API_KEY;
const COHERE_API_KEY = process.env.REACT_APP_COHERE_API_KEY;
const J2_API_KEY = process.env.REACT_APP_J2_API_KEY;

export const availableBots = [
    {
        name: "ChatGPT",
        slug: "chat-gpt-4o-mini",
        company: "OpenAI",
        api: 'https://api.openai.com/v1/chat/completions',
        model: "gpt-4o-mini",
        apiKey: OPENAI_API_KEY,
        checked: true
    },
    {
        name: "Command R",
        slug: "command-r",
        company: "Cohere",
        api: 'https://api.cohere.ai/v1/chat',
        model: "command-r",
        apiKey: COHERE_API_KEY,
        checked: true
    },
    {
        name: "Jamba",
        slug: "jamba-1.5-mini",
        company: "AI21 Labs",
        api: 'https://api.ai21.com/studio/v1/chat/completions',
        model: 'jamba-1.5-mini',
        apiKey: J2_API_KEY,
        checked: true
    },
];
