import { useContext } from "react";
import Spinner from "./spinner";
import { PromptContext } from "../store/prompt-store";
import Counter from "./counter";

export default function PromptTitle({ index }) {

    const { maxLength, currentPrompt } = useContext(PromptContext);

    return (
        !currentPrompt.loading && currentPrompt.title && <div className={`card my-3 ${!index ? `bg-primary text-light` : `bg-secondary text-light`}`}>
            <div className="card-body d-flex flex-wrap py-2">
                {currentPrompt.loading ? <Spinner /> : <i className="bi bi-terminal"></i>}
                <span className="fw-medium mx-2">{currentPrompt.title}</span>
                <Counter className={`align-self-center ms-auto`} background="bg-primary-dark" title={currentPrompt.title} length={maxLength} />
            </div>
        </div>
    );
};
