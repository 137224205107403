import { createContext, useState } from "react";
import { availableBots } from "../data";

export const PromptContext = createContext(null);

export function usePromptStore() {
    const maxLength = 140;
    const sortOrder = availableBots.map(e => e.slug);
    const [title, setTitle] = useState('');
    const [currentPrompt, setCurrentPrompt] = useState({ title: '', answers: [], loading: false });
    const [prompts, setPrompts] = useState([]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setCurrentPrompt({ ...currentPrompt, title: title, loading: true });
        currentPrompt.title && currentPrompt.answers.length && setPrompts([currentPrompt, ...prompts]);
        setTitle('');
    };

    return {
        maxLength,
        sortOrder,
        title,
        setTitle,
        handleSubmit,
        prompts,
        setPrompts,
        currentPrompt,
        setCurrentPrompt
    };
};

export function Prompts({ children }) {
    const promptStore = usePromptStore();

    return (
        <PromptContext.Provider value={promptStore}>
            <main>
                {children}
            </main>
        </PromptContext.Provider>
    )
}
