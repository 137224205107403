import { useContext, useEffect, useRef, useState } from "react";
import { PromptContext } from "../store/prompt-store";
import Counter from "./counter";
import { availableBots } from "../data";

const SearchForm = () => {
    const { title, maxLength, currentPrompt, setTitle, handleSubmit } = useContext(PromptContext);
    const [titleDisable, setTitleDisable] = useState(false);
    const ref = useRef();

    useEffect(() => {
        currentPrompt.title && currentPrompt.answers.length < availableBots.length ? setTitleDisable(true) : setTitleDisable(false);
        // !titleDisable && ref.current.focus();
    }, [currentPrompt.title, currentPrompt.answers, currentPrompt.loading, titleDisable])

    return (
        <div className="row">
            <div className="col-md-10 offset-md-1">
                <form className='prompt position-relative mb-2' onSubmit={handleSubmit}>
                    <Counter className={`prompt-count mb-2 ms-2`} title={title} length={maxLength} />
                    <div className='input-group'>
                        <input ref={ref} autoFocus placeholder='Enter your prompt here...' maxLength={maxLength} disabled={titleDisable} className="form-control form-control-lg" value={title} type='search' id="prompt" onChange={(e) => setTitle(e.target.value)} />
                        <button className="btn btn-outline-secondary" type='submit' disabled={!title}>Search</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default SearchForm;
