export default function Dialog({ dialogRef, setModal, id, title, children }) {

    return (
        <div ref={dialogRef} className="modal fade" id={id}>
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-4" id="modalDialogTitle">{title}</h1>
                        <button type="button" className="btn-close" onClick={() => setModal(false)} data-bs-dismiss="modal"></button>
                    </div>
                    <div className='modal-body'>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};
