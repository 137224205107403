export default function FeaturedAiModels({ img }) {

    return (
        <div className='row g-4'>
            <div className='col-lg-4 d-flex align-items-center justify-content-center'>
                <div className='rounded rounded-3 overflow-hidden img-placeholder d-flex justify-content-center align-items-center'>
                    <img src={img} className='object-fit-cover' alt='Network with large points' />
                </div>
            </div>
            <div className='col-lg-8 d-flex flex-column'>
                <div className='flex-grow-1'>
                    <p className='lead'>Rawbot is the premier platform for exploring and comparing the capabilities of artificial intelligence models. Whether you're a researcher, developer, or business leader, Rawbot is your destination for making informed decisions about the AI models that best fit your needs.</p>
                    <p className='lead'>Here is a selection of popular AI models available for comparison.</p>
                </div>
                <ol className='list-unstyled mb-0 flex-grow-1'>
                    <li className='list-unstyled-item'>
                        <div className='row align-items-center'>
                            <div className='col'>
                                <h3 className='h5 text-primary'>GPT-4o Mini</h3>
                            </div>
                            <div className='col-auto d-flex'>
                                <a className='btn btn-link justify-self-end' title='More Informaton' target="_blank" rel="noreferrer" href='https://platform.openai.com/'>
                                    <i className="bi bi-box-arrow-up-right"></i>
                                </a>
                            </div>
                        </div>
                        <p>Developed by OpenAI, ChatGPT-4o Mini is a variant of the ChatGPT-4 model that is optimized for performance and faster response times on edge devices with limited computational resources, such as smartphones and tablets. ChatGPT-4o is based on the OpenAI's GPT-4 model and is designed to interact with users in a conversational manner, answering questions and providing information on a wide range of topics.</p>
                    </li>
                    <li className='list-unstyled-item'>
                        <div className='row align-items-center'>
                            <div className='col'>
                                <h3 className='h5 text-primary'>Command R</h3>
                            </div>
                            <div className='col-auto d-flex'>
                                <a className='btn btn-link justify-self-end' title='More Informaton' target="_blank" rel="noreferrer" href='https://www.cohere.ai/'>
                                    <i className="bi bi-box-arrow-up-right"></i>
                                </a>
                            </div>
                        </div>
                        <p>Cohere Command R is a family of highly scalable language models that balance high performance with strong accuracy.</p>
                    </li>
                    <li className='list-unstyled-item'>
                        <div className='row align-items-center'>
                            <div className='col'>
                                <h3 className='h5 text-primary'>Jamba 1.5 Mini</h3>
                            </div>
                            <div className='col-auto d-flex'>
                                <a className='btn btn-link justify-self-end' title='More Informaton' target="_blank" rel="noreferrer" href='https://www.ai21.com/jamba'>
                                    <i className="bi bi-box-arrow-up-right"></i>
                                </a>
                            </div>
                        </div>
                        <p className='mb-0'>Jamba 1.5 Mini is a compact and portable version of a larger Jamba device, which is typically a smart speaker or a similar electronic gadget. The "Mini" designation indicates that it is smaller and likely more portable than the standard version.</p>
                    </li>
                </ol>
            </div>
        </div>
    );
};
