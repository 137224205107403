import ModelBadge from "./model-badge";
import { availableBots } from "../data";

const PromptAnswer = ({ item }) => {
    const bot = availableBots.find(e => e.slug === item.bot);

    return (
        <div className='col ai-bot-content'>
            <div className={`card h-100`}>
                <div className='card-header'>
                    <i className="me-auto bi bi-chat-square-dots-fill"></i>
                    <span className='mx-2'>{bot.name}</span>
                    <ModelBadge model={bot.model} />
                </div>
                <div className='card-body'>
                    <p className={!item.content ? `text-muted` : item.type === 'error' ? ` text-danger` : ``}>{item.content ? item.content : `Sorry, no answer was returned. :-/`}</p>
                </div>
                <div className='card-footer fs-7 text-muted'>Uses the official API provided by <strong>{bot.company}</strong></div>
            </div>
        </div>
    )
}

export default PromptAnswer;
