import Spinner from "./spinner";

const Loader = () => {
    return (
        <div className='card-body d-flex justify-content-center'>
            <Spinner className={`text-primary`} />
        </div>
    )
}

export default Loader;
