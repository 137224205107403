import { useContext, useEffect } from "react";
import ModelBadge from "./model-badge";
import { PromptContext } from "../store/prompt-store";
import Loader from "./loader";
import AnswerContent from "./answer-content";
import { AnswerContext } from "../store/answer-store";

const Bot = ({ item }) => {
    const { loading, fetchData, answer } = useContext(AnswerContext);
    const { sortOrder, currentPrompt, setCurrentPrompt, setPromptLoading, prompts, setPrompts } = useContext(PromptContext);

    useEffect(() => {
        !currentPrompt.loading && setCurrentPrompt({ ...currentPrompt, answers: [...currentPrompt.answers, answer] })
    }, [answer]);

    useEffect(() => {
        setCurrentPrompt({ ...currentPrompt, answers: currentPrompt.answers.sort((a, b) => sortOrder.indexOf(a.bot) - sortOrder.indexOf(b.bot)) })
    }, [currentPrompt.answers]);

    useEffect(() => {
        currentPrompt.loading && fetchData(item, currentPrompt.title);
        setCurrentPrompt({ ...currentPrompt, answers: [], loading: false })
    }, [currentPrompt.loading]);

    return (
        !currentPrompt.loading && currentPrompt.title ? <div className='col ai-bot-content'>
            <div className={`card border-primary h-100`}>
                <div className='card-header border-primary'>
                    <i className="me-auto bi bi-chat-square-dots-fill"></i>
                    <span className='mx-2'>{item.name}</span>
                    <ModelBadge model={item.model} />
                </div>
                {loading ? <Loader /> : <AnswerContent />}
                <div className='card-footer fs-7 text-muted'>Uses the official API provided by <strong>{item.company}</strong></div>
            </div>
        </div> : null
    )
}

export default Bot;
