export default function WhyRawbot({ img }) {

    return (
        <div className='row g-4'>
            <div className='col-lg-4 d-flex align-items-center justify-content-center'>
                <div className='rounded rounded-3 img-placeholder overflow-hidden d-flex justify-content-start align-items-center'>
                    <img src={img} className='object-fit-cover' alt='Network' />
                </div>
            </div>
            <div className='col-lg-8'>
                <p className='lead'>Our innovative, user-friendly tool simplifies the selection process, enabling you to identify and understand the strengths and weaknesses of each AI model with ease. Comparing AI models is crucial for a variety of reasons, including:</p>
                <ol className='list-unstyled mb-0'>
                    <li className='list-unstyled-item'>
                        <h3 className='h5 text-primary'>Performance Optimization</h3>
                        <p>By comparing AI models, you can identify which model excels in the specific task you require, ensuring that you achieve optimal results in your project or business.</p>
                    </li>
                    <li className='list-unstyled-item'>
                        <h3 className='h5 text-primary'>Strengths and Weaknesses Identification</h3>
                        <p>Different AI models are designed to tackle varied tasks, often displaying strengths in some areas while underperforming in others. Comparing models allows you to understand these differences and select the one that aligns best with your needs.</p>
                    </li>
                    <li className='list-unstyled-item'>
                        <h3 className='h5 text-primary'>Customization and Tuning</h3>
                        <p>Comparing AI models can reveal opportunities for customization and fine-tuning. By understanding each model's capabilities, you can better adapt them to your use case or even develop a hybrid approach that combines the strengths of multiple models.</p>
                    </li>
                    <li className='list-unstyled-item'>
                        <h3 className='h5 text-primary'>Cost and Efficiency Analysis</h3>
                        <p>AI models vary in terms of computational demands and resource requirements. Comparing models helps you identify those that provide the most cost-effective and efficient solutions, ensuring the best return on your investment.</p>
                    </li>
                    <li className='list-unstyled-item'>
                        <h3 className='h5 text-primary'>Informed Decision Making</h3>
                        <p className='mb-0'>Understanding the capabilities and limitations of different AI models enables you to make educated decisions about which model to use, ultimately leading to more successful outcomes in research, development, and business applications.</p>
                    </li>
                </ol>
            </div>
        </div>
    );
};
