
export const Footer = () => {

    return (
        <ul className="nav mt-4 justify-content-center">
            <li className="nav-item">
                <button className={`nav-link link-secondary`} data-bs-toggle="modal" data-bs-target="#terms-and-conditions" aria-current="page">Terms and Conditions</button>
            </li>
            <li className="nav-item">
                <button className={`nav-link link-secondary`} data-bs-toggle="modal" data-bs-target="#privacy-policy" aria-current="page">Privacy Policy</button>
            </li>
        </ul>
    )
}
