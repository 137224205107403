import { useContext } from "react";
import { AnswerContext } from "../store/answer-store";

export default function AnswerContent() {

    const { answer } = useContext(AnswerContext);

    return (
        <div className='card-body'>
            <p className={!answer.content ? `text-muted` : answer.type === 'error' ? ` text-danger` : ``}>{answer.content ? answer.content : `Sorry, no answer was returned. 😔`}</p>
        </div>
    );
};
