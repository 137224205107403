import Header from './components/header';
import { Prompts } from './store/prompt-store';
import SearchForm from './components/search-form';
import Answers from './components/answers';
import { Fragment, useEffect, useRef, useState } from 'react';
import { Modal } from 'bootstrap';
import Dialog from './components/dialog';
import imgNetwork from "./assets/images/rawbot-network.jpg";
import imgNetworkCloseup from "./assets/images/rawbot-network-closeup.jpg";
import imgMind from "./assets/images/rawbot-mind.jpg";
import imgKarbula from "./assets/images/karbula-icon-16p.png";
import WhyRawbot from './components/dialogs/why-rawbot';
import FeaturedAiModels from './components/dialogs/featured-ai-models';
import Faq from './components/dialogs/faq';
import { Footer } from './components/footer';
import TermsAndConditions from './components/dialogs/terms-and-conditions';
import PrivacyPolicy from './components/dialogs/privacy-policy';

const App = () => {
    const [modal, setModal] = useState(false);
    const modalRef = useRef();

    useEffect(() => {
        const bsModal = new Modal(modalRef.current)
        modal ? bsModal.show() : bsModal.hide();
    }, [modal])

    return (
        <Fragment>
            <nav className="navbar navbar-expand-lg">
                <div className="container-fluid">
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav flex-grow-1">
                            <li className="nav-item">
                                <button className={`nav-link`} data-bs-toggle="modal" data-bs-target="#why-rawbot" aria-current="page">Why Rawbot?</button>
                            </li>
                            <li className="nav-item">
                                <button className={`nav-link`} data-bs-toggle="modal" data-bs-target="#featured-ai-models" aria-current="page">Featured AI Models</button>
                            </li>
                            <li className="nav-item">
                                <button className={`nav-link`} data-bs-toggle="modal" data-bs-target="#faq" aria-current="page">FAQ</button>
                            </li>
                            <li className="nav-item ms-lg-auto">
                                <a className="nav-link" href="https://www.karbula.org"><img src={imgKarbula} alt='Symbol of Karbula' /><span className='ms-2 d-lg-none'>Karbula</span></a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="mailto:rawbot@karbula.org">Contact</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="https://twitter.com/RawbotAI"><i className='bi bi-twitter'></i><span className='ms-2 d-lg-none'>Twitter</span></a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="https://www.facebook.com/rawbotai"><i className='bi bi-facebook'></i><span className='ms-2 d-lg-none'>Facebook</span></a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="https://www.linkedin.com/company/rawbot-ai/"><i className='bi bi-linkedin'></i><span className='ms-2 d-lg-none'>LinkedIn</span></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <div className='container-xxl flex-fill py-4 px-lg-5 d-flex flex-column'>
                <Header />
                <Prompts>
                    <SearchForm />
                    <Answers />
                </Prompts>
                <Footer />
                <Dialog dialogRef={modalRef} setModal={setModal} id={`why-rawbot`} title={`Why Rawbot?`}>
                    <WhyRawbot img={imgNetwork} />
                </Dialog>
                <Dialog dialogRef={modalRef} setModal={setModal} id={`featured-ai-models`} title={`Featured AI Models`}>
                    <FeaturedAiModels img={imgNetworkCloseup} />
                </Dialog>
                <Dialog dialogRef={modalRef} setModal={setModal} id={`faq`} title={`Frequently Asked Questions`}>
                    <Faq img={imgMind} />
                </Dialog>
                <Dialog dialogRef={modalRef} setModal={setModal} id={`terms-and-conditions`} title={`Terms and Conditions`}>
                    <TermsAndConditions />
                </Dialog>
                <Dialog dialogRef={modalRef} setModal={setModal} id={`privacy-policy`} title={`Privacy Policy of Karbula`}>
                    <PrivacyPolicy />
                </Dialog>
            </div>
            <div className="d-flex py-5 justify-content-end flex-fill flex-column">
                <ul className="nav justify-content-center">
                    <li className="nav-item">
                        <a href="https://aitoptools.com/?post_type=tool&p=58320" title="Rawbot">
                            <img src="https://aitoptools.com/wp-content/uploads/2023/07/embed-image.png" alt="Featured on AI Top Tools" width={300} />
                        </a>
                    </li>
                </ul>
            </div>
        </Fragment>
    );
}

export default App;
