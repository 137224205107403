export const getRequest = (model, prompt) => {
    switch (model) {
        case "gpt-4o-mini":
            return {
                model: model,
                messages: [{ role: "user", content: prompt }],
                max_tokens: 100,
            };
        case "jamba-1.5-mini":
            return {
                model: model,
                messages: [{ role: "user", content: prompt }],
                temperature: 0.5,
                n: 1,
                max_tokens: 100,
                stop: [],
                response_format: {"type": "text"},
            }
        case "command-r":
            return {
                model: model,
                message: prompt,
                temperature: 0.5,
                max_tokens: 100,
                prompt_truncation: "AUTO",
            }
        default:
            return null;
    }
}

export const getAnswer = (model, response) => {
    switch (model) {
        case "gpt-4o-mini":
            return response.choices[0].message.content;
        case "jamba-1.5-mini":
            return response.choices[0].message.content;
        case "command-r":
            return response.text;
        default:
            return null;
    }
}
